import { OtpTimer } from '@gigin-work-space/common-ui';
import { otpGeneralDetailStore, setOtpGeneralDetails } from '@gigin-work-space/store';
import { pluralizeString } from '@gigin-work-space/utils';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { axiosInstance, endpoints } from '../../services';
import { StyledOTPContainerResend, StyledOTPInput } from '../../utils';
import { Header, MetaDetailsContainer } from '../common-ui';

export const OtpVerification = () => {
  const theme = useTheme();
  const { otpGeneralDetail } = useSnapshot(otpGeneralDetailStore);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [otpValue, setotpValue] = useState('');
  const [isOtpCorrect, setIsOtpCorrect] = useState(true);
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dataCollectionId = localStorage.getItem('dataCollectionId');
  const linkExpiryDuration = JSON.parse(localStorage.getItem('general_details') as string).validity;

  // function to handle otp input change
  const handleOtpChange = (value: string) => {
    if (value.length > 6) return;
    setotpValue(value);
    if (value.length === 6) {
      handleOtpSubmit(value);
    }
  };

  // function to submit otp on press of enter key
  const handleOtpSubmit = async (otp: string) => {
    try {
      setIsApiLoading(true);
      const body = {
        verificationToken: otpGeneralDetail?.verification_token,
        code: otp,
        dataCollectionId: dataCollectionId,
      };
      const response = await axiosInstance.post(`${endpoints.POST_VERIFICATION_OTP}`, body);
      if (response.data.status) {
        setIsOtpCorrect(true);
        localStorage.setItem('verification_token', otpGeneralDetail ? otpGeneralDetail?.verification_token : '');
        localStorage.setItem('auth_token', JSON.stringify(response.data.data));
        enqueueSnackbar('OTP verification Successful', {
          variant: 'success',
          autoHideDuration: 2000,
        });
        setOtpGeneralDetails(null);
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
        return;
      }
      throw new Error('Invalid Otp');
    } catch (error) {
      setIsOtpCorrect(false);
    } finally {
      setIsApiLoading(false);
    }
  };

  // function to resend otp
  const handleResendOtp = async () => {
    try {
      setotpValue('');
      setIsOtpCorrect(true);
      setIsApiLoading(true);
      const body = { dataCollectionId: dataCollectionId };
      const response = await axiosInstance.post(`${endpoints.GET_VERIFICATION_OTP}`, body);
      if (response.data.success) {
        setOtpGeneralDetails(response.data.data);
        return;
      }
      throw new Error('Failed to resend OTP');
    } catch (error) {
      enqueueSnackbar('Failed to resend OTP', { variant: 'error', autoHideDuration: 2000 });
    } finally {
      setIsApiLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      navigate('/dashboard');
    }
    if (!otpGeneralDetail?.value) navigate(`/${dataCollectionId}`);
  }, [dataCollectionId, navigate, otpGeneralDetail]);
  return (
    <>
      {/* Header section */}
      <Header />
      {/* main Section */}
      <section className="px-6 py-8 w-full">
        <Stack spacing={3}>
          {/* Note warning */}
          <MetaDetailsContainer
            iconName="warning_blue_icon.svg"
            noteText={`Note this link will expire in ${linkExpiryDuration.value} ${pluralizeString(
              linkExpiryDuration.type,
              linkExpiryDuration.value,
            )}`}
          />
          {/* Description */}
          <div>
            <Typography className="bk-headline3 text-bk_text_primary">Authentication</Typography>
            <Typography className="bK-body2 text-bk_text_secondary mt-1">
              Authenticate your mobile number via otp and start your verification process!
            </Typography>
          </div>
          {/* otp sent on mobile title */}
          <Typography className="bk-sub-heading2 text-bk_text_secondary">
            We have sent a verification code to +91 {otpGeneralDetail?.value}
          </Typography>
          <div className="flex flex-col justify-start">
            {/* otp input section */}
            <StyledOTPInput
              theme={theme}
              isOtpCorrect={isOtpCorrect}
              value={otpValue}
              numInputs={6}
              autoComplete="true"
              shouldAutoFocus={true}
              isInputNum={true}
              onChange={handleOtpChange}
            />
            {/* resend otp section */}
            <StyledOTPContainerResend>
              {!isOtpCorrect && (
                <Box component="span" className="bk-sub-heading2 text-bk_alert_failure">
                  {t('bk_otpverify_errormsg')}
                </Box>
              )}
              <OtpTimer
                minutes={2}
                seconds={0}
                text={t('bk_otpverify_resendtimer') as string}
                resendText={t('bk_common_resendotp') as string}
                resendOtp={handleResendOtp}
                otpValid={isOtpCorrect}
              />
            </StyledOTPContainerResend>
          </div>
        </Stack>
      </section>
    </>
  );
};

export default OtpVerification;
