import { proxy } from 'valtio';

export interface IPermissionStoreProps {
  [key: string]: boolean; // Index signature to allow using string keys
}
export const permissionStore = proxy<IPermissionStoreProps>({
  canReadAnalytics: false,
  canReadOrder: false,
  canReadOps: false,
  hasToken: false,
  canCreateOrder: false,
  canUpdateOrder: false,
  canDeleteOrder: false,
  canReadCompanyProfile: false,
  canCreateCompanyProfile: false,
  canUpdateCompanyProfile: false,
  canReadTeams: false,
  canUpdateTeams: false,
  canCreateTeams: false,
  canReadUsers: false,
  canCreateUsers: false,
  canUpdateUsers: false,
  canReadPackage: false,
  canCreatePackage: false,
  canUpdatePackage: false,
  canReadPreference: false,
  canCreatePreference: false,
  canUpdatePreference: false,
  canReadPreferences: false,
  canCreatePreferences: false,
  canUpdatePreferences: false,
  canReadAgreement: false,
  canReadRoles: false,
  canCreateRoles: false,
  canUpdateRoles: false,
  canReadReport: false,
  canUploadTemplate: false,
  canUpdateTemplate: false,
  canDeleteTemplate: false,
  canReadTemplate: false,
  canUploadDocument: false,
  canUpdateDocument: false,
  canDeleteDocument: false,
  canReadDocument: false,
});
