import { IDataCollectionPayload } from '@gigin-work-space/model';
import { proxy } from 'valtio';

// Store to maintain candidate data collection details
export const saveDataPayloadStore = proxy<{
  saveDataPayload: IDataCollectionPayload[];
}>({
  saveDataPayload: [],
});

/* ------------Actions-------------*/

/*
Action to set the value of data payload
params: 
 - saveDataPayload - Array of payload objects i.e form fields values
*/
export const setSaveDataPayload = (saveDataPayload: IDataCollectionPayload[]) => {
  saveDataPayloadStore.saveDataPayload = saveDataPayload;
};

// function to create payload body
export const createPayloadBody = (data: any) => {
  data.forEach((element: any) => {
    if (element.field_data_type === 'calender' && element.field_value === 'Currently Working') {
      handleUpdateFieldParameter(element.field_id, '');
    } else {
      handleUpdateFieldParameter(element.field_id, element.field_value);
    }
  });
};

// function to update/append the payload state with new updated object
const updateOrAppendPayloadBody = (updatedObject: any) => {
  const index = saveDataPayloadStore.saveDataPayload.findIndex((obj) => obj.id === updatedObject.id);

  // If the object exist, replace the value at that index
  if (index !== -1) {
    saveDataPayloadStore.saveDataPayload[index] = updatedObject;
    return;
  }
  // If the object doesn't exist, append it to the array
  saveDataPayloadStore.saveDataPayload = [...saveDataPayloadStore.saveDataPayload, updatedObject];
};

// Function to create new payload object and update the payload state
export const handleUpdateFieldParameter = (parameterId: string, value: any) => {
  const updatedData = {
    id: parameterId,
    dataCollected: { ...value },
  };
  if (updatedData.id && updatedData.dataCollected) {
    updateOrAppendPayloadBody(updatedData);
  }
};
