import { empCBUsageStore, handleUpdateFieldParameter } from '@gigin-work-space/store';
import { DATE_DD_MM_YYYY, DATE_MM_YYYY, DATE_YYYY } from '@gigin-work-space/utils';
import { DateValidationError, DateView } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { useSnapshot } from 'valtio';
import { BKStyledDatePicker } from '../../../utils';

export interface DateInputFieldProps {
  params: any;
}

export const DateInputField = (props: DateInputFieldProps) => {
  const { params } = props;
  const { cbValue, targetField } = useSnapshot(empCBUsageStore);

  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs(params.field_value.value).toString() === 'Invalid Date' ? null : dayjs(params.field_value.value),
  );
  const [enableFutureDate, setEnableFutureDate] = React.useState(params.field_name === 'passport_passport_valid_till');
  const [error, setError] = React.useState<DateValidationError | string | null>(null);

  const renderDateView = () => {
    switch (params?.validation?.rule) {
      case 'calender_date_month_year':
        return DATE_DD_MM_YYYY as unknown as DateView[];
      case 'dob_date_month_year':
        return DATE_DD_MM_YYYY as unknown as DateView[];
      case 'calender_year':
        return DATE_YYYY as unknown as DateView[];
      case 'calender_month_year':
        return DATE_MM_YYYY as unknown as DateView[];
      default:
        return DATE_DD_MM_YYYY as unknown as DateView[];
    }
  };

  const formatDateView = () => {
    switch (params?.validation?.rule) {
      case 'calender_date_month_year':
        return 'MMM D, YYYY';
      case 'dob_date_month_year':
        return 'MMM D, YYYY';
      case 'calender_year':
        return 'YYYY';
      case 'calender_month_year':
        return 'MMM, YYYY';
      default:
        return 'MMM D, YYYY';
    }
  };

  const handleBlur = () => {
    if (!value) {
      setError('This field is required'); // Set the appropriate error message

      setValue(value as dayjs.Dayjs | null);
      handleUpdateFieldParameter(params.field_id, { value: '' });
    }
  };

  const handleChange = (newValue: React.SetStateAction<dayjs.Dayjs | null | unknown>) => {
    const selectedDate = newValue as dayjs.Dayjs;
    const currentDate = dayjs();
    const yearsDifference = currentDate.diff(selectedDate, 'year');

    if (params.field_name === 'date_of_birth' && yearsDifference < 18) {
      // Set an appropriate error message
      setError('Age must be at least 18 years');
      setValue(newValue as dayjs.Dayjs | null);
      handleUpdateFieldParameter(params.field_id, { value: '' });
      return;
    }

    // Reset error if the date is valid
    setError(null);
    handleUpdateFieldParameter(params.field_id, {
      value: newValue?.toString(),
    });
    setValue(newValue as dayjs.Dayjs | null);
  };

  // memoized effect to trigger on invalid date
  const errorMessage = React.useMemo(() => {
    let msg = '';
    switch (error) {
      case 'invalidDate':
        msg = 'Invalid date';
        break;
      case 'disableFuture':
        msg = 'Future date is not allowed';
        break;
      case 'Age must be at least 18 years':
        msg = 'Age must be at least 18 years';
        break;
      case 'This field is required':
        msg = 'This field is required';
        break;

      default:
        // Reset the error message if there is no error
        handleUpdateFieldParameter(params.field_id, {
          value: value?.toString(),
        });
        msg = '';
        break;
    }
    if (msg) {
      handleUpdateFieldParameter(params.field_id, {
        value: '',
      });
    }
    return msg;
  }, [error, value]);

  // memoized value for disabled date
  // TODO: Fix for current;y woring and uncheck
  const disableDate = React.useMemo(() => {
    if (params.field_name === targetField) {
      setValue(null);
      handleUpdateFieldParameter(params.field_id, {
        //value: cbValue ? 'Currently Working' : '',
        value: cbValue ? '' : '', //TODO: Need to Handel when Adding Validation for Currently Working
      });
    }
    return cbValue && params.field_name === targetField;
  }, [cbValue, targetField]);

  return !disableDate ? (
    <DemoContainer components={['DatePicker']} sx={{ paddingTop: '5px', overflow: 'hidden' }}>
      <BKStyledDatePicker
        label={params?.validation?.is_optional ? `${params?.label.en}` : params?.label.en}
        value={value}
        error={error ? true : false}
        disableFuture={!enableFutureDate}
        disabled={disableDate}
        onChange={(newValue) => handleChange(newValue)}
        onError={(newError) => setError(newError)}
        openTo="year"
        views={renderDateView()}
        format={formatDateView()}
        slotProps={{
          textField: {
            name: params.field_name,
            helperText: errorMessage,
            FormHelperTextProps: {
              style: { color: error ? 'red' : 'inherit' },
            },
            InputProps: {
              onBlur: () => handleBlur(), // Pass onBlur handler here
              // If you need to apply styles based on the error state
            },
          },
        }}
      />
    </DemoContainer>
  ) : (
    ''
  );
};
