import { ISection } from '@gigin-work-space/model';
import { DATA_STATUS } from '@gigin-work-space/utils';
import { Box, Grid, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { BKStyledButton, StyledDivider } from '../../utils';
import { FilePreview, MetaDetailsContainer } from '../common-ui';

export interface ViewableContainerHocProps {
  sectionList?: ISection[];
  mode?: string;
  relId: string;
  fields: any[];
  cancelConfirm?: () => void;
  navigateToStep: (param: string) => void;
}

// array of field data types to be excluded in view/confirm mode
const exculdedFieldDataType = ['file', 'employment_checkbox', 'address_checkbox'];

export const ViewableContainerHoc = (props: ViewableContainerHocProps) => {
  const { mode = 'view', relId, fields, sectionList, cancelConfirm, navigateToStep } = props;
  const [stepsLabel, setStepsLabel] = useState([]);
  const [currentActiveStep, setCurrentActiveStep] = useState(0);

  // function to check if media is present or not
  const isMediaEmpty = (fields: any) => {
    return fields?.some((data: any) => data?.field_data_type === 'file' && !!data?.field_value?.value);
  };

  // function to navigate to clicked section step if its completed
  const navigateToClickedStep = (data: any) => {
    navigateToStep(data?.meta?.goto?.destination);
  };

  // function to render field value
  const renderFieldValue = (data: any) => {
    switch (data?.validation?.rule) {
      case 'calender_date_month_year':
        return dayjs(data?.field_value?.value).format('MMM D, YYYY');
      case 'dob_date_month_year':
        return dayjs(data?.field_value?.value).format('MMM D, YYYY');
      case 'calender_year':
        return dayjs(data?.field_value?.value).format('YYYY');
      case 'calender_month_year': {
        if (data?.field_value?.value?.toLowerCase() === 'currently working') {
          return 'Currently Working';
        }
        return dayjs(data?.field_value?.value).format('MMMM, YYYY');
      }
      case 'aadhaar_number': {
        // Remove non-digit characters from the input value
        const newValue = data?.field_value?.value?.replace(/\D/g, '');
        // Format Aadhaar number by inserting spaces
        const formattedValue = newValue?.replace(/(.{4})/g, '$1 ').trim();
        return formattedValue;
      }
      default:
        return data?.field_value?.value
          ? data?.field_data_type === 'zip'
            ? data?.field_value?.file_name
            : data?.field_value?.value
          : '-';
    }
  };

  //   SIDE EFFECT
  useEffect(() => {
    const handleActiveStep = () => {
      if (sectionList) {
        const activeIndex = sectionList?.findIndex((data) => data?.rel_id === relId);
        if (activeIndex !== -1) {
          setCurrentActiveStep(activeIndex);
        } else {
          setCurrentActiveStep(0);
        }
      }
    };
    handleActiveStep();
  }, []);

  return (
    <>
      {/* Stepper Section */}
      {sectionList && sectionList?.length > 1 && mode !== 'confirm' && (
        <section className="px-6 pt-6 w-full">
          <Box sx={{ width: '100%', position: 'sticky', top: 3, zIndex: 999 }}>
            <div className="bk-stepper-scroll">
              <Stepper activeStep={currentActiveStep} alternativeLabel>
                {sectionList?.map((data, id) => (
                  <Step
                    key={data?.rel_id}
                    completed={data?.status?.toLowerCase() === DATA_STATUS.COMPLETED}
                    disabled={data?.status?.toLowerCase() === DATA_STATUS.PENDING}
                    onClick={() => navigateToClickedStep(data)}>
                    <StepLabel className="completed-step-icon">{data?.title?.en}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <Box className="bk-stepper-side-blur" />
          </Box>
        </section>
      )}
      <section
        className={
          mode === 'confirm' ? '' : stepsLabel?.length > 1 ? 'bk-main-stepper-section' : 'bk-main-view-section'
        }>
        <Stack spacing={2}>
          {/* Edit Section */}
          {mode === 'confirm' && (
            <>
              <Stack spacing={1} padding={'0 1.5rem'}>
                <MetaDetailsContainer
                  iconName="info_yellow_icon.svg"
                  noteText="After submitting the details you will not be able to add/edit information"
                  fontStyle="bK-body1"
                  bgColor="bk_tag_orange_bg"
                  textColor="bk_text_secondary"
                />
                <BKStyledButton fullWidth variant="outlined" onClick={cancelConfirm}>
                  Edit details
                </BKStyledButton>
              </Stack>
              <StyledDivider />
            </>
          )}
          {/* Details Section */}
          <Stack spacing={1} padding={'0 1.5rem'}>
            {fields &&
              fields?.map(
                (data: any) =>
                  !exculdedFieldDataType.includes(data?.field_data_type) && (
                    <Grid container spacing={1} key={data?.field_id} sx={{ marginLeft: 0 }} alignItems={'flex-start'}>
                      <Grid item xs={5}>
                        <Typography className="bK-body2 text-bk_text_secondary ">{data?.label?.en}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography className="bk-sub-heading2 text-bk_text_primary">
                          {data?.field_value?.value ? renderFieldValue(data) : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  ),
              )}
          </Stack>
          {/* IF Media is empty then don't show */}
          {isMediaEmpty(fields) && (
            <>
              <StyledDivider />
              {/* Media Section */}
              <Stack spacing={1} padding={'0 1.5rem'}>
                {/* Details Section */}
                <Typography className="bK-body2 text-bk_text_secondary pl-2">
                  {relId === 'basic_detail' ? 'Profile Pic' : 'Document(s)'}
                </Typography>
                <Grid container spacing={1} sx={{ marginLeft: 0 }} alignItems={'center'}>
                  {fields &&
                    fields?.map(
                      (data: any) =>
                        data?.field_data_type === 'file' && (
                          <Grid item xs={relId === 'basic_detail' ? 6 : data?.grid} key={data?.field_id}>
                            <FilePreview
                              params={data}
                              fileDetails={data?.field_value}
                              mode="view"
                              url={data?.field_value?.value}
                            />
                          </Grid>
                        ),
                    )}
                </Grid>
              </Stack>
            </>
          )}
        </Stack>
      </section>
    </>
  );
};
