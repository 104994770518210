import { handleUpdateFieldParameter, setEmpCheckBoxUsage } from '@gigin-work-space/store';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useMemo } from 'react';

export interface EmploymentCheckboxInputFieldProps {
  params: any;
}

export const EmploymentCheckboxInputField = (props: EmploymentCheckboxInputFieldProps) => {
  const { params } = props;
  const [checked, setChecked] = React.useState(params?.field_value?.value?.toLowerCase() === 'yes' ? true : false);

  React.useEffect(() => {
    setEmpCheckBoxUsage(checked, params?.rule[0]?.target, params?.rule[0]?.action);
    handleUpdateFieldParameter(params.field_id, {
      value: checked ? 'Yes' : 'No',
    });
  }, []);

  useMemo(() => {
    setChecked(params?.field_value?.value?.toLowerCase() === 'yes' ? true : false);
  }, [params]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setChecked(checked);
    setEmpCheckBoxUsage(checked, params?.rule[0]?.target, params?.rule[0]?.action);
    handleUpdateFieldParameter(params.field_id, {
      value: checked ? 'Yes' : 'No',
    });
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />}
      label={params.validation.is_optional ? `${params.label.en}` : params.label.en}
    />
  );
};
