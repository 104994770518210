import { SelfDeclarationStore } from '@gigin-work-space/store';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Header, MetaDetailsContainer } from '../common-ui';
import { CommonSection, CustomConcentSection } from './consent-content';

export const SelfDeclarationAfterCompletion = () => {
  const [concentMessage, setConcentMessage] = useState<string>('');

  useEffect(() => {
    if (localStorage.getItem('general_details')) {
      setConcentMessage(JSON.parse(localStorage.getItem('general_details') as string).company_info?.concent_message);
    }
  }, []);

  const formattedDateString = SelfDeclarationStore?.uploadTimeString;

  const note =
    SelfDeclarationStore.self_declaration.signature_type.toLowerCase() === 'esign'
      ? `You E-signed the declaration on ${formattedDateString}`
      : `You had uploaded the manually signed document on ${formattedDateString}`;

  const generalDetail = JSON.parse(localStorage.getItem('general_details') as string);

  return (
    <>
      <Header headerType="headerBackNav" headerTitle="Self Declaration" />
      <Grid className="pl-6 pr-10" item xs={12}>
        <MetaDetailsContainer iconName="info_blue_icon.svg" noteText={note} fontStyle="bk-sub-heading3" />
      </Grid>
      {/* TODO: Will come up with an approach later to make it externalize */}
      {concentMessage ? (
        <CustomConcentSection generalDetail={generalDetail} formattedDateString={formattedDateString} />
      ) : (
        <CommonSection generalDetail={generalDetail} formattedDateString={formattedDateString} />
      )}
      {/* Footer continue button */}
    </>
  );
};

export default SelfDeclarationAfterCompletion;
