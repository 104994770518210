import { fetchFileWithAuth } from '@gigin-work-space/utils';
import React, { useEffect, useState } from 'react';

interface ProfilePicWithCredentialsProps {
  imageUrl: string;
  imageAlt?: string;
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
}

const ImageWithCredentials: React.FC<ProfilePicWithCredentialsProps> = ({
  imageUrl,
  imageAlt,
  height,
  className,
  width,
  style,
}) => {
  const [imageSrc, setImageSrc] = useState<string>('');

  useEffect(() => {
    const loadImage = async () => {
      try {
        const fileBlob = await fetchFileWithAuth(imageUrl);
        const objectURL = URL.createObjectURL(fileBlob);
        setImageSrc(objectURL);
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        // Fallback to default image if loading fails
        setImageSrc('');
      }
    };

    loadImage();

    // Cleanup function to revoke the object URL when the component unmounts
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [imageUrl]);

  return imageSrc ? (
    <img src={imageSrc} alt={imageAlt} width={width} height={height} className={className} style={style} />
  ) : (
    <div className="w-full object-contain h-28">Image not available</div>
  );
};

export default ImageWithCredentials;
