import { handleUpdateFieldParameter } from '@gigin-work-space/store';
import { InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { BKStyledSelect, BKStyledSelectGroup } from '../../../utils';

export interface SelectInputFieldProps {
  params: any;
}

export const SelectInputField = (props: SelectInputFieldProps) => {
  const { params } = props;
  const [value, setValue] = useState(params.field_value.value ? params.field_value.value : '');

  //   function to handle text change
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setValue(event.target.value as string);
    handleUpdateFieldParameter(params.field_id, {
      value: event.target.value,
    });
  };

  return (
    <>
      {/* TODO: replace en with current language selected symbol */}
      <BKStyledSelectGroup fullWidth>
        <InputLabel id="simple-select-label">{params.label.en}</InputLabel>
        <BKStyledSelect
          labelId="simple-select-label"
          id="simple-select"
          value={value}
          label={params.validation.is_optional ? `${params.label.en} (optional)` : params.label.en}
          onChange={handleChange}>
          {params.option.data.fields.map((menu: any) => (
            <MenuItem value={menu.value} key={menu.value}>
              {menu.display_text.en}
            </MenuItem>
          ))}
        </BKStyledSelect>
      </BKStyledSelectGroup>
    </>
  );
};
