import { ITermsConsent } from '@gigin-work-space/model';
import { setOtpGeneralDetails } from '@gigin-work-space/store';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Checkbox, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance, endpoints } from '../../services';
import { BKStyledButton } from '../../utils';
import { CommonLoader, DisplayImage, Footer, Header } from '../common-ui';
import { ErrorPage } from './error-page';

export const DCGeneralInstruction = () => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [candidateName, setCandidateName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [concentMessage, setConcentMessage] = useState('');
  const [consentTermsAccepted, setConsentTermsAccepted] = useState<ITermsConsent>({
    consentChecked: false,
    termsChecked: false,
  });

  const navigate = useNavigate();
  const { dataCollectionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  // function to get general details
  const getGeneralDetails = useCallback(async () => {
    // TODO: handle success fail cases later
    try {
      setIsPageLoading(true);
      const response = await axiosInstance.get(`${endpoints.GET_INITIAL_INFO}/${dataCollectionId}`);
      if (response.data.success) {
        setCandidateName(response.data.data.candidate_info.name);
        setCompanyName(response.data.data.company_info.name);

        setConcentMessage(
          response.data.data.company_info?.concent_message?.replace(/\[Candidate Name\]/g, candidateName),
        );
        localStorage.setItem('auth_token', '');

        localStorage.setItem('general_details', JSON.stringify(response.data.data));
      }
    } catch (error) {
      setIsApiError(true);
    } finally {
      setIsPageLoading(false);
    }
  }, [dataCollectionId]);

  // function to toggle check states
  const handleChecked = (checkboxName: keyof ITermsConsent) => {
    setConsentTermsAccepted((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }));
  };

  // function to generate OTP on candidate mobile number
  const handleGenerateOtp = useCallback(async () => {
    // TODO: Handle success fail scenarios in UI and also different error codes later
    try {
      setIsApiLoading(true);
      const body = { dataCollectionId: dataCollectionId };
      const response = await axiosInstance.post(`${endpoints.GET_VERIFICATION_OTP}`, body);
      if (response.data.success) {
        setOtpGeneralDetails(response.data.data);
        navigate('/verify-otp');
        return;
      }
      throw new Error('Failed to send OTP');
    } catch (error) {
      enqueueSnackbar('Failed to send OTP', { variant: 'error', autoHideDuration: 2000 });
    } finally {
      setIsApiLoading(false);
    }
  }, [dataCollectionId, navigate, enqueueSnackbar]);
  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    const storedDataCollectionId = localStorage.getItem('dataCollectionId');
    if (token && storedDataCollectionId === dataCollectionId) {
      navigate('/dashboard');
      return;
    }

    localStorage.setItem('dataCollectionId', dataCollectionId as string);
    getGeneralDetails();
  }, [dataCollectionId, getGeneralDetails, navigate]);
  // memoized effect to toggle cta btn toggle state
  const isCtaBtnDisabled = useMemo(() => {
    const dependencyStateArray = [consentTermsAccepted.consentChecked];
    // return true if all values are true, otherwise false
    return dependencyStateArray.some((value) => value === false);
  }, [consentTermsAccepted]);

  if (isPageLoading) {
    return <CommonLoader type="circular-loader" />;
  }
  return (
    <>
      {isApiError && <ErrorPage />}
      {!isApiError && (
        <>
          {/* Header section */}
          <Header />
          {/* Instruction Section */}
          {/* TODO: Will come up with an approach later to make it externalize */}
          {concentMessage ? (
            <section className="bk-main-instruction-section">
              {' '}
              <div dangerouslySetInnerHTML={{ __html: concentMessage }} />
            </section>
          ) : (
            <section className="bk-main-instruction-section">
              <DisplayImage imageName="verifyin-dark.svg" imageType="image" width="126px" height="34px" />
              <section className="flex flex-col gap-4 mt-6">
                <p className="bK-body2 text-bk_text_secondary">
                  I, <strong>{candidateName}</strong>, hereby authorize <strong>{companyName}</strong> and its retained
                  third parties including Gigin Technologies Private Limited to collect, process, investigate, store,
                  use, transfer, maintain my personal data (including sensitive data), if required for verification, in
                  arch to obtain an employment verification report in connection to my application for employment.
                </p>
                <p className="bK-body2 text-bk_text_secondary">
                  The employment verification report may include information regarding my character, general reputation,
                  personal characteristics, Education(Authentication of acquired or pursuing Degrees/Diplomas),
                  Employment history, Credit History, Court Records, including criminal verification records as
                  permitted by law, Passport verification, Permanent Account Number Verification, Drug Test, Fingerprint
                  Verification, Address Verification, References from professional and Personal associates as may be
                  applicable and any other check as found relevant for the profile.
                </p>

                <ul className="list-disc ml-4 space-y-4 pl-0 bK-body2 text-bk_text_secondary">
                  <li>
                    I further understand and agree that the employment verification report may be obtained at any time
                    and any number of times as necessary before, during or post my employment.
                  </li>
                  <li>
                    I provide my consent to the company or the third parties for the processing of any my sensitive
                    personal information obtained for the purpose of verification and call me in case any further
                    information is required.
                  </li>
                  <li>
                    I understand that some or all of the information I have provided in this application form or that
                    may be obtained through various sources during the process of the verification of the verification
                    will be held as digitized or physical records for a period and for such use as may be permitted
                    under the applicable laws.
                  </li>
                  <li>
                    I hereby authorize all previous employers, educational institutions, consumer reporting agencies and
                    other persons or entities having information about me to provide such information to the company or
                    any other third party or parties retained by them for the purpose.
                  </li>
                  <li>
                    I understand that the continuance of the employment or the offer of employment is contingent upon
                    the outcome of the background check conducted on me.
                  </li>
                </ul>
                <p className="bK-body2 text-bk_text_secondary">
                  The proof of identity enclosed and self-attested for reference. A photostat, or any other copy, of
                  this instrument bearing my signature shall be equally legally valid as the original.
                </p>
                <p className="bK-body2 text-bk_text_secondary">
                  All the information furnished by me in the Background Verification form is true to the best of my
                  knowledge.
                </p>
              </section>
            </section>
          )}
          {/* Footer continue button */}
          <Footer padding="8px 16px">
            <section className="flex flex-col w-full">
              <div className="flex items-center">
                <Checkbox
                  size="small"
                  onChange={() => handleChecked('consentChecked')}
                  checked={consentTermsAccepted.consentChecked}
                />
                <Typography className="bK-body2 text-bk_text_secondary">I've read & provide my consent </Typography>
              </div>
              {/* TODO: commented terms & condition for now */}
              {/* <div className="flex items-center">
                <Checkbox
                  size="small"
                  onChange={() => handleChecked('termsChecked')}
                  checked={consentTermsAccepted.termsChecked}
                />
                <Typography className="bK-body2 text-bk_text_secondary">I accept the terms & conditions</Typography>
              </div> */}
              <div className="mt-2">
                <BKStyledButton
                  fullWidth
                  variant="contained"
                  size="large"
                  endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
                  disabled={isCtaBtnDisabled || isApiLoading}
                  onClick={handleGenerateOtp}>
                  Continue to OTP verification
                </BKStyledButton>
              </div>
            </section>
          </Footer>
        </>
      )}
    </>
  );
};

export default DCGeneralInstruction;
