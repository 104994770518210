export interface IProgressComponentProps {
  status: unknown;
}

export interface BgvOrdersNewTableProps {
  status: string;
}
export interface IProgressStatusProps {
  status: string;
  message: string;
}

export interface ICheckInProgress {
  invite: IProgressStatusProps;
  dataCollection: IProgressStatusProps;
  checkInProgress: IProgressStatusProps;
  quality: IProgressStatusProps;
  reportGenerated: IProgressStatusProps;
}

export interface ICurrentProgess {
  message: string;
  title: string;
  status: string;
}

export interface ICheckList {
  checkout_id: string;
  product_name: string;
  product_description: string;
  icon: string;
  check_status: IProgressStatusProps;
}

export interface IOrderCandidateListData {
  _id: string;
  name: string;
  jobRole: string;
  checkProgress: ICheckInProgress;
  currentProgess: ICurrentProgess;
  checkList: ICheckList[];
  createdAt: string;
  initiatedBy_image: string;
  order_status: string;
}

export interface IOrderJobRole {
  title: string;
  total_count: number;
}

export interface IGetCandidateFullStatus {
  title: string;
  color: string;
  bgColor: string;
  icon: string;
  outline: string;
  status: string;
  message: string;
}

export interface IOrderData {
  _id: string;
  orderId: string;
  order_title: string;
  createdAt: string;
  initiatedBy_image: string;
  order_status: string;
  job_role: IOrderJobRole[];
  invoice_id: string;
  orderCompletionStatus: IOrderCompletionStatus;
}

// job.interface.ts

export interface IDraftOrderData {
  id: string;
  createdAt: string;
  initiatedBy_image: string;
  status: string;
  job_role: {
    [key: string]: number;
  };
}

export interface IMetaProps {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}
export const IMetaPropsInitial = {
  currentPage: 0,
  itemsPerPage: 0,
  totalItems: 0,
  totalPages: 0,
};

export interface IOrderCompletionStatus {
  success_percentage: number;
  fail_percentage: number;
  warn_percentage: number;
  success_count: number;
  fail_count: number;
  warn_count: number;
  total_count: number;
}
