// eslint-disable-next-line @nx/enforce-module-boundaries
import { ICandidateInterfaceTable, ImetaDataProps } from '@gigin-work-space/model';
import { proxy } from 'valtio';

interface ICheckoutType {
  account_id: string;
  service_suite_id: string;
  checkout_initiation_id: string;
  confirmation_id: string;
  step_3: boolean;
  orderId: string;
  isactive: boolean;
  initiate_id: string;
  order_init_id: string;
  currentPage: number;
  meta: ImetaDataProps;
  totalCandidate: number;
  filedata: File | unknown;
  isLoading: boolean;
  nextPage: boolean;
  UploadFileName: string;
  UploadFileSize: number;
  FilterDays: number;
  candidateData: ICandidateInterfaceTable[];
  tabIndex: number;
  reload: boolean;
}
export const CheckoutStore: ICheckoutType = proxy({
  account_id: '',
  service_suite_id: '',
  checkout_initiation_id: '',
  confirmation_id: '',
  step_3: false,
  orderId: '',
  isactive: true,
  initiate_id: '',
  order_init_id: '',
  currentPage: 1,
  isLoading: false,
  totalCandidate: 0,
  nextPage: false,
  meta: {
    totalPages: 0,
    totalItems: 0,
    currentPage: 0,
    itemsPerPage: 0,
    duplicateItem: 0,
    invalidItem: 0,
    validItem: 0,
  },
  filedata: '',
  UploadFileName: '',
  UploadFileSize: 0,
  FilterDays: 15,
  candidateData: [],
  tabIndex: 0,
  reload: false,
});
