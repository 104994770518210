import { Route, Routes, useParams } from 'react-router-dom';
import {
  DCGeneralInstruction,
  Dashboard,
  DataCollectionHoc,
  ErrorPage,
  OtpVerification,
  PrivacyPolicy,
} from '../../app/components';
import SelfDeclarationAfterCompletion from '../../app/components/self-declaration-after-completion';
import SelfDeclarationEsign from '../../app/components/self-declaration-esign';
import SelfDeclarationManual from '../../app/components/self-declaration-manual-sign';
import ProtectedRoute from './protected-route';

// Route configuration for entire application
export const RouterWidget = () => {
  const { dataCollectionId } = useParams();
  //localStorage.setItem('dataCollectionId', dataCollectionId as string);
  return (
    <Routes>
      {/* Candidate Data Collection START */}
      <Route path="/verify-otp" element={<OtpVerification />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute path={'/dashboard'}>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/collect-data/*"
        element={
          <ProtectedRoute path={'/collect-data/*'}>
            <DataCollectionHoc />
          </ProtectedRoute>
        }
      />
      <Route path="/:dataCollectionId" element={<DCGeneralInstruction />} />
      <Route path="/selfDeclarationManual" element={<SelfDeclarationManual />} />
      <Route path="/selfDeclarationEsign" element={<SelfDeclarationEsign />} />
      <Route path="/selfDeclarationAfterCompletion" element={<SelfDeclarationAfterCompletion />} />
      {/* Candidate Data Collection END */}s
      <Route path="/" element={<ErrorPage />} />
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
};
