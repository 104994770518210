import * as Yup from 'yup';

// Object Schema validation for rule - name
export const nameSchema = Yup.object().shape({
  value: Yup.string()
    .required('This is required field')
    .min(3, 'Name should be at least 3 characters')
    .max(30, 'Name should not exceed 30 characters')
    .matches(/^[a-zA-Z. ]+$/, 'Name can only contain letters and spaces'),
});

// Object Schema validation for rule - last_name
export const lastNameSchema = Yup.object().shape({
  value: Yup.string()
    .required('This is required field')
    .min(1, 'This is required field')
    .max(30, 'Last Name should not exceed 30 characters')
    .matches(/^[a-zA-Z. ]+$/, 'Name can only contain letters and spaces'),
});

export const citySchema = Yup.object().shape({
  value: Yup.string()
    .required('This is required field')
    .max(50, 'City is too long')
    .matches(/^[a-zA-Z. ]+$/, 'City name can only contain letters and spaces'),
});
// Object Schema validation for rule - email
export const emailSchema = Yup.object().shape({
  value: Yup.string()
    .required('Email is required')
    .max(256, 'Email should not exceed 256 characters')
    .email('Invalid email address'),
});

// Object Schema validation for rule - aadhaar_number
export const aadhaarNumberSchema = Yup.object().shape({
  value: Yup.string()
    .required('Aadhaar number is required')
    .matches(/^\d{12}$/, 'Invalid Aadhaar number'),
});

// Object Schema validation for rule - pancard_number
export const pancardNumberSchema = Yup.object().shape({
  value: Yup.string()
    .required('PAN card number is required')
    .matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/, 'Invalid PAN card number, Example ABCDE1234Z'),
});

// Object Schema validation for rule - voter_number
export const voterNumberSchema = Yup.object().shape({
  value: Yup.string()
    .required('Voter ID is required')
    .matches(/^[A-Z]{3}[0-9]{7}$/, 'Invalid Voter ID'),
});

// Object Schema validation for rule - driving_licence_number
export const drivingLicenceNumberSchema = Yup.object().shape({
  value: Yup.string()
    .required('Driving license number is required')
    .matches(
      /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
      'Invalid Driving license number',
    ),
});

// Object Schema validation for rule - pincode
export const pincodeSchema = Yup.object().shape({
  value: Yup.string()
    .matches(/^[1-9][0-9]{5}$/, 'Invalid PIN code')
    .required('PIN code is required'),
});

// Object Schema validation for rule - number
export const numberSchema = Yup.object().shape({
  value: Yup.string()
    .required('This is required field')
    .matches(/^[0-9]+$/, 'Only numbers are allowed'),
});

// Object Schema validation for rule - grade
export const gradeSchema = Yup.object().shape({
  value: Yup.string().required('Grade is required').max(8, 'Invalid grade/CGPA/percentage'),
});

// Object Schema validation for rule - mobile
export const mobileSchema = Yup.object().shape({
  value: Yup.string()
    .matches(/^[0-9]{10}$/, 'Invalid mobile number')
    .required('Mobile number is required'),
});

// Object Schema validation for rule - alphanumeric
export const alphanumericSchema = Yup.object().shape({
  value: Yup.string()
    .required('This is a required field')
    .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed'),
});

// Object Schema validation for rule - bank_ifsc
export const bankIfscSchema = Yup.object().shape({
  value: Yup.string()
    .trim()
    .uppercase()
    .matches(/^[A-Za-z]{4}0[A-Za-z0-9]{6}$/, 'Invalid IFSC code')
    .required('IFSC code is required'),
});

// Object Schema validation for rule - passport_number
export const passportSchema = Yup.object().shape({
  value: Yup.string()
    .trim()
    .matches(/^[A-Za-z][0-9]\d\s?\d{4}[0-9]$/, 'Invalid passport number')
    .required('Passport number is required'),
});

// Object Schema validation for rule -institution_name
export const institutionNameSchema = Yup.object().shape({
  value: Yup.string()
    .required('This is required field')
    .matches(/^[a-zA-Z0-9\s.'-]+$/, 'Acceptable values are alphanumeric characters, spaces, apostrophes, and hyphens')
    .min(2, 'Should contain at least 2 characters')
    .max(100, 'Should not exceed 100 characters'),
});

// Object Schema validation for rule - address_line
export const addressLineSchema = Yup.object().shape({
  value: Yup.string()
    .required('This is required field')
    .matches(
      /^[a-zA-Z0-9\s.,#-/]+$/,
      'Acceptable values are alphanumeric characters, spaces, periods, commas, hash, and hyphens',
    )
    .min(1, 'Should contain at least 1 characters')
    .max(300, 'Should not exceed 300 characters'),
});

// Object Schema validation for rule -designation_name
export const designationNameSchema = Yup.object().shape({
  value: Yup.string()
    .required('This is required field')
    .matches(/^[a-zA-Z0-9\s.'-]+$/, 'Acceptable values are alphanumeric characters, spaces, apostrophes, and hyphens')
    .min(3, 'Should contain at least 3 characters')
    .max(50, 'Should not exceed 50 characters'),
});

// Object Schema validation for rule - uan_number
export const uanNumberSchema = Yup.object().shape({
  value: Yup.string().matches(/^\d{12}$/, 'Invalid UAN number'),
});

// Object Schema validation for rule - bank_account_number
export const bankAccountNumberSchema = Yup.object().shape({
  value: Yup.string()
    .required('Account number is required')
    .matches(/^\d{9,18}$/, 'Invalid account number'),
});

export const currentlyWorkingSchema = Yup.object().shape({
  value: Yup.string().matches(/^(?:Yes|No)$/, 'Value should Yes or No'),
});

export const documentUploadSchema = Yup.object().shape({
  value: Yup.string().matches(/.+/, 'Document details are invalid, upload might have failed'),
});

export const calenderDateMonthYearSchema = Yup.object().shape({
  value: Yup.string().test('is-date', 'Invalid date', (value) => {
    // Check if the value is not empty
    if (!value) return false;

    // Try parsing the value as a Date
    const parsedDate = new Date(value);

    // Check if the parsed date is valid
    return !isNaN(parsedDate.getTime());
  }),
});

export const dateOfBirthSchema = Yup.object().shape({
  value: Yup.string().test('is-date-and-age', 'Must be at least 18 years old', (value) => {
    // Check if the value is not empty
    if (!value) return false;

    // Try parsing the value as a Date
    const parsedDate = new Date(value);

    // Check if the parsed date is valid
    if (isNaN(parsedDate.getTime())) return false;

    const diffMs = Date.now() - parsedDate.getTime();

    const age = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365));

    return age >= 18;
  }),
});

export const imageUploadSchema = Yup.object().shape({
  value: Yup.string().matches(/.+/, 'Image details are invalid, upload might have failed'),
});

export const calenderYearSchema = Yup.object().shape({
  value: Yup.string().test('is-date', 'Invalid date', (value) => {
    // Check if the value is not empty
    if (!value) return false;

    // Try parsing the value as a Date
    const parsedDate = new Date(value);

    // Check if the parsed date is valid
    return !isNaN(parsedDate.getTime());
  }),
});

export const textSchema = Yup.object().shape({
  value: Yup.string(),
});

export const checkboxSchema = Yup.object().shape({
  value: Yup.string().matches(/^(?:Yes|No)$/, 'Value should Yes or No'),
});
